import React from "react";
import { Helmet } from "react-helmet";
import "./social-block.css";
import steamIcon from "../../images/steam-icon.png";
import youtubeIcon from "../../images/youtube-icon.png";
import kickstarterIcon from "../../images/kickstarter-icon.png";
import twitterIcon from "../../images/twitter-icon.png";
import instagramIcon from "../../images/instagram-icon.png";

export default function SocialBlock() {
  class SocialInfo {
    public id: string;
    public url: string;
    public label: string;
    public icon: any;

    public constructor(id: string, url: string, label: string, icon: any) {
      this.id = id;
      this.url = url;
      this.label = label;
      this.icon = icon;
    }
  }

  const socialInfoList : SocialInfo[] = [
    new SocialInfo(
      "steam",
      "https://store.steampowered.com/app/1858410/Terra_Maega/",
      "Visit Steam Page",
      steamIcon
    ),
    new SocialInfo(
      "kickstarter",
      "https://www.kickstarter.com/projects/daemontale/terra-maega",
      "Visit Kickstarter",
      kickstarterIcon
    ),
    new SocialInfo(
      "twitter",
      "https://twitter.com/daemontale",
      "Visit Twitter Page",
      twitterIcon
    ),
    new SocialInfo(
      "youtube",
      "https://www.youtube.com/channel/UCjmTJhu1slWpeaQreC6x7BQ",
      "Visit Youtube Channel",
      youtubeIcon
    ),
    new SocialInfo(
      "instagram",
      "https://www.instagram.com/daemon_tale/",
      "Visit Instagram Account",
      instagramIcon
    )
  ];

  return (
    <div className="text-center sticky-top terra-maega-social-block" id="terraMaegaSocialBlock">
      <Helmet>
        <script>
          {`
            const updateSocialBlock = function (){
              if (typeof window != "undefined" && typeof document != "undefined") {
                const ele = document.getElementById("terraMaegaSocialBlock");
                if (ele && window.scrollY >= 200) {
                  ele.style.zIndex = "4000";
                }
                else if (ele) {
                  ele.style.zIndex = "1000";
                }
              }
            }
            document.removeEventListener("scroll", updateSocialBlock);
            document.addEventListener("scroll", updateSocialBlock);
          `}
        </script>
      </Helmet>
      <p className="mb-1 social-cta-text">
        🧙 Follow Along 🧙
      </p>
      {socialInfoList.map((info) => {
        return (
          <a
            className = "btn d-inline-block mx-2 p-1 social-block-button"
            href = {info.url}
            aria-label = {info.label}
            role = "button"
            key = {info.id}
            target = "_blank"
            rel = "noopener noreferrer"
          >
            <img
              src = {info.icon}
              className = "social-block-button-icon"
              alt = "button icon"
              width = "48"
              height = "48"
            />
          </a>
        )
      })}
    </div>
  )
}
