import React from "react";
import "./jumbotron-block.css";
import terraMaegaCover from "../../images/cover-2-new-no-title.png";
import terraMaegaTitle from "../../images/terra-maega-title-pixel.png";

export default function JumbotronBlock() {
  const jumbotronBlockStyle = {
    position: "relative",
    backgroundImage: `url(${terraMaegaCover})`,
    backgroundSize: "auto",
    backgroundPosition: "center top",
    width: "100%",
    height: "400px",
    maxHeight: "64vw",
  };

  return (
    <div className="terra-maega-jumbotron-block" style={jumbotronBlockStyle}>
      <img
        className = "terra-maega-jumbotron-title"
        src = {terraMaegaTitle}
        alt = "Terra Maega Logo"
        width = "693"
        height = "261"
      ></img>
    </div>
  )
}
