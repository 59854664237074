import React, { useState, useEffect } from "react";
import "./mail-cta.css";
import background from "../../images/customBackground3.png";

export default function MailCta() {
  let emailInput: string;
  const [isAlert, setAlert] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);

  const emailFormStyle = {
    display: (!isSubmitted) ? "block" : "none",
  }
  const submittedFormStyle = {
    display: (isSubmitted) ? "block" : "none",
  }
  const emailAlertStyle = {
    display: (isAlert) ? "block" : "none",
  }

  const updateEmailInput = function(event: any): void {
    emailInput = event.target.value;
  }

  const validateEmail = function(): boolean {
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailInput);
  }

  const backgroundStyle = {
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
    // height: "150px",
    // maxHeight: "25vw",
  };

  const submitEmail = async function(event: any): void {
    if (validateEmail()) {
      event.preventDefault();
      const response = await fetch(
        `https://docs.google.com/forms/d/e/1FAIpQLSfBaeUuJ9jNRIJIIzsyX8dh7ohDR7M8qvqu5cRkOvWWyfKo1g/formResponse?entry.51896780=${emailInput}`,
        {
          method: "POST",
          cache: "no-cache",
          headers: {
            "Accept": "*/*",
            "Content-Type": "application/x-www-form-urlencoded",
            "Accept-Encoding": "gzip, deflate, br",
            "Host": "google.com",
          },
        }
      )
        .catch((err) => {
          // console.log(err);
        })
        .finally(() => {
          console.log("here");
          setSubmitted(true);
        })
    }
    else {
      setAlert(true);
    }
  }

  return (
    <div className="text-center pb-4 daemontale-mail-cta" style={backgroundStyle}>
      <div className="daemontale-mail-cta-form" style={emailFormStyle}>
        <h3 className="py-4 px-3 daemontale-mail-cta-title">
          ✨ Subscribe to the Daemontale Newsletter ✨
        </h3>
        <p className="px-3 daemontale-mail-cta-description">
          Terra Maega is a ~ <b><i>solo-made game</i></b> ~ and it takes a lot of time to make on my own! 😳
          <br />
          You can follow along with updates on the game while the game is still in development:
        </p>
        <form className="row m-0" onSubmit={submitEmail}>
          <div className="d-none d-md-block col-1 col-lg-2"></div>
          <div className="col-8 col-md-6 col-lg-5">
            <input
              type = "email"
              className = "form-control"
              id = "daemontaleMailCtaEmailInput"
              aria-label = "Enter Email"
              title = "Enter Email"
              placeholder = "Email"
              value = {emailInput}
              onChange = {updateEmailInput}
              required
            />
          </div>
          <div className="col-4 col-lg-3">
            <button
              type = "submit"
              className = "btn btn-primary col-12"
              title = "Submit Email"
              aria-label = "Submit Email"
            >
              Subscribe
            </button>
          </div>
          <div className="d-none d-md-block col-1 col-lg-2"></div>
        </form>
        <br />
        <div className="row m-0 email-alert-box" style={emailAlertStyle}>
          <div className="d-none d-md-block col-1 col-lg-2"></div>
          <div className="alert alert-danger p-2 col-12 col-md-10 col-lg-8" role="alert">
            Please enter a valid email
            <button
              type="button"
              className="btn-close alert-close"
              data-bs-dismiss="alert"
              aria-label="Close Validation Message">
            </button>
          </div>
          <div className="d-none d-md-block col-1 col-lg-2"></div>
        </div>
      </div>
      <div className="py-4 daemontale-mail-cta-submitted" style={submittedFormStyle}>
        <h2 className="py-4 submitted-title">
          ❤️️ Thanks for subscribing ❤️️
        </h2>
        <p className="m-0 px-4 submitted-text">
          Now you'll be able to receive major announcements concerning <i>Terra Maega's</i> progress.
        </p>
      </div>
    </div>
  )
}
