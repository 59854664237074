import React from "react";
import BaseLayout from "../components/base-layout/base-layout";
import JumbotronBlock from "../components/jumbotron-block/jumbotron-block";
import SocialBlock from "../components/social-block/social-block";
import VideoBlock from "../components/video-block/video-block";
import AppIconBlock from "../components/app-icon-block/app-icon-block";
import FeatureBlock from "../components/app-icon-block/feature-block";
import FeatureBlockList from "../components/feature-block-list/feature-block-list";
import MailCta from "../components/mail-cta/mail-cta";
import Cover2New from "../images/cover-2-new.png";
import type { HeadFC } from "gatsby";

export default function TerraMaegaPage() {
  return (
    <div>
      <BaseLayout>
        <JumbotronBlock />
        <SocialBlock />
        <VideoBlock />
        <AppIconBlock />
        <FeatureBlockList />
        <MailCta />
        <AppIconBlock />
      </BaseLayout>
    </div>
  )
}

export const Head = () => (
  <>
    <title>Terra Maega - A Magic PixelArt RPG - Solo Made Indiegame</title>
    <meta
      name="description"
      content="A pixelart rpg made by a solo indiegame developer. Inspired by Stardew Valley and Pokemon - craft magic items and capture magical creatures to become a Master."
    />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <meta name="author" content="Daemontale" />

    <meta property="og:title" content="Terra Maega - Solo Indiegame inspired by Stardew Valley and Pokemon" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content={`http://www.daemontale.com/${Cover2New}`} />
    <meta property="og:url" content="https://www.daemontale.com/terra-maega" />
    <meta name="twitter:card" content="Can you become a Master Mage in just 4 years? Terra Maega is an indie pixelart rpg made by a single developer. Play as an apprentice Mage, exploring a fantasy world full of magical encounters." />

    <meta property="og:description" content="Can you become a Master Mage in just 4 years? Terra Maega is an indie pixelart rpg made by a single developer. Play as an apprentice Mage, exploring a fantasy world full of magical encounters." />
    <meta property="og:site_name" content="Daemontale - Solo Indiegame Developer" />
    <meta name="twitter:image:alt" content="Terra Maega - Solo Indiegame inspired by Stardew Valley and Pokemon" />
  </>
)
