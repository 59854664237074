import React from "react";
import "./video-block.css";
import background3 from "../../images/customBackground3.png";

export default function VideoBlock() {
  const videoBlockStyle = {
    backgroundImage: `url(${background3})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
    width: "100%",
    height: "100%",
    backgroundColor: "#361c41",
    paddingBottom: "80px"
  };

  return (
    <div className="text-center terra-maega-video-block" style={videoBlockStyle}>
      <iframe
        className="terra-maega-video"
        src="https://www.youtube.com/embed/zAL7rc2pkD4"
      ></iframe>
      <div className="terra-maega-video-description-block">
        <p className="mb-0 terra-maega-video-description">
          ✨ Congratulations! You're finally an Apprentice Mage ✨
          <br />
          You're just one step closer to becoming a Master.
          <br />
          <br />
          Travel the land, craft magical items, and duel other Mages.
          All with the help of your cute magical familiars - the Maegas.
          <br />
          <br />
          A magic apprenticeship only lasts 4 years.
          <br />
          Can you prove you have what it takes to become a Master Mage in such a short time?
        </p>
      </div>
    </div>
  )
}
