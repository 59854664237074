import React from "react";
import "./app-icon-block.css";
import terraMaegaIcon from "../../images/terra-maega-icon.png";

export default function AppIconBlock() {
  return (
    <div className="text-center terra-maega-app-icon-block">
      <img
        className = "terra-maega-app-icon"
        src = {terraMaegaIcon}
        alt = "Terra Maega Icon"
        width = "64"
        height = "64"
      ></img>
    </div>
  )
}
