import React from "react";
import "./feature-block.css";

export default function FeatureBlock(props) {
  const featureBlockStyle = {
    flexDirection: (props.isReverse) ? "row-reverse" : "row",
  };
  const featureImgStyle = {
    backgroundImage: `url(${props.img})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
    height: "350px",
    maxHeight: "50vw",
  };
  const featureTextStyle = {
    backgroundImage: `url(${props.background})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
    height: "350px",
    maxHeight: "50vw",
    color: (!props.isReverse) ? "#eae57e" : "#f6f5f7",
    fontSize: "16px",
    lineHeight: "2",
    boxShadow: "inset 0 0 10px #000000",
  };

  return (
    <div className="row d-flex m-0 p-0 terra-maega-feature-block" style={featureBlockStyle}>
      <div className="col-6 terra-maega-feature-image" style={featureImgStyle}></div>
      <div className="col-6 d-flex text-center terra-maega-feature-text" style={featureTextStyle}>
        <p className="col-12 p-0 m-0 align-self-center">
          {props.text}
        </p>
      </div>
    </div>
  )
}
