import React from "react";
import FeatureBlock from "../feature-block/feature-block";
import random from "../../images/random.png";
import furnitureMovement from "../../images/furnitureMovement.png";
import slashBurn from "../../images/slashBurn.png";
import capturing from "../../images/capturing.png";
import lightning from "../../images/lightning.png";
import dialogue from "../../images/dialogue.png";
import potioneering from "../../images/potioneering.png";
import sparkle from "../../images/sparkleOverlay.png";
import stars from "../../images/starsOverlay.png";

export default function FeatureBlockList() {
  class Feature {
    public id: string;
    public img: any;
    public background: any;
    public text: string;
    public isReverse: boolean;

    public constructor(id: string, img: any, background: any, text: string, isReverse: boolean) {
      this.id = id;
      this.img = img;
      this.background = background;
      this.text = text;
      this.isReverse = isReverse;
    }
  }
  const featureList: Feature[] = [
    new Feature(
      "homeCustomization",
      furnitureMovement,
      stars,
      `Buy and upgrade homes that you'll be able to customize to your liking.`,
      false
    ),
    new Feature(
      "battleMages",
      lightning,
      sparkle,
      `Use the Maegas you gather to learn new magical abilities that you can use in duels.`,
      true
    ),
    new Feature(
      "captureMaegas",
      capturing,
      stars,
      `Discover and capture tons of new Maega as you explore the land.`,
      false
    ),
    new Feature(
      "overworldExploration",
      slashBurn,
      sparkle,
      `Use what you learn to navigate the environment! Mages know no borders.`,
      true
    ),
    new Feature(
      "crafting",
      potioneering,
      stars,
      `Craft powerful magical items and useful potions to help on your journey to become a Master Mage.`,
      false
    ),
    new Feature(
      "relationships",
      dialogue,
      sparkle,
      `You're not alone; make friends and build relationships with people you meet along the way!`,
      true
    )
  ];

  return (
    <div className="terra-maega-feature-block-list">
      {featureList.map((feature) => {
        return (
          <FeatureBlock
            key = {feature.id}
            img = {feature.img}
            background = {feature.background}
            text = {feature.text}
            isReverse = {feature.isReverse}
          ></FeatureBlock>
        )
      })}
    </div>
  )
}
